import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import API from '../helpers/api'
import { getLocal, getPageClass } from '../helpers/helpers'
import Routes from '../pages/Routes'
import Ribbon from './Ribbon'
import SideMenu from './SideMenu'

// home component
class Home extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            path: null,
            role: null,
            time: Date.now()
        }
    }
    // on mount
    async componentDidMount() {
        // get location path name 
        const path = window.location.pathname
        // find route by path name
        const page = Routes.find(item => path.includes(item.route))
        // set path
        if (page) { this.setState({ path: page.route }) }
        // get user id
        const user_id = getLocal('user-data', {}).data.id
        // request user data
        const user_dt = await API.getUserById(user_id)
        // get role id
        const role_id = user_dt.role
        // request role data
        const role_dt = await API.getRoleById(role_id)
        // set role
        this.setState({ role: role_dt.name.replace(/ /g, '_').toLowerCase() }, () => {
            // check for empty or invalid path name
            if (path === '/' || page === undefined) {
                // get first route possible to the user role
                const item = Routes.find(item => item.roles.includes(this.state.role))
                // navigate route
                this.props.history.push(item.route)
                // set path
                this.setState({ path: item.route })
            }
        })
        // set interval to looping actions
        setInterval(() => this.setState({ time: Date.now() }), 15000)
    }
    // get pages for each route 
    pages() {
        // return mapped routes
        return Routes.map((item, index) => {
            // return main page
            return ([
                <Route
                    key={index}
                    exact={true}
                    path={item.route}
                    children={
                        <>
                            <Ribbon title={item.label} route={item.route} />
                            <div className={getPageClass(item.route)}>
                                <item.component
                                    role={this.state.role}
                                    path={this.state.path}
                                    time={this.state.time}
                                />
                            </div>
                        </>
                    }
                />].concat(item.nodes.map((node, node_index) => {
                    // return sub page
                    return (
                        <Route
                            key={index + '_' + node_index}
                            exact={true}
                            path={item.route + node.path}
                            children={
                                <>
                                    <Ribbon
                                        title={node.label || item.label}
                                        route={item.route}
                                        child={true}
                                    />
                                    <div className={getPageClass(item.route, node.path)}>
                                        <node.component
                                            role={this.state.role}
                                            path={item.route + node.path}
                                            time={this.state.time}
                                        />
                                    </div>
                                </>
                            }
                        />
                    )
                }))
            )
        })
    }
    // method to render
    render() {
        return (
            <div>
                {/* side menu */}
                <SideMenu
                    list={Routes}
                    path={this.state.path}
                    role={this.state.role}
                    call={path => this.setState({ path })}
                />
                {/* page content */}
                <div className="page-root">
                    <Switch>
                        { this.pages()}
                    </Switch>
                </div>
            </div>
        )
    }
}

export default withRouter(Home)