import React from 'react'
import { withRouter } from 'react-router-dom'
import './Ribbon.css'
import { setLocal } from '../helpers/helpers'

class Ribbon extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // method binds
        this.routeBack = this.routeBack.bind(this)
        this.signOut = this.signOut.bind(this)
    }
    // method to route back
    routeBack() {
        // check for child mode
        if(this.props.child) {
            // navigate back to previous page
            this.props.history.goBack()
        }
    }
    // method to sign out
    signOut() {
        // clear storage
        setLocal('user-data', null)
        // navigate to root
        window.location.href = '/'
    }
    // method to render
    render() {
        return (
            <div className="ribbon">
                {/* sign out button */}
                <button
                    className="ribbon-exit"
                    onClick={this.signOut}>
                    Sign out
                </button>
                {/* title and back button */}
                <button
                    className="ribbon-head"
                    data-icon={this.props.child ? 'route-back' : this.props.route + '-black'}
                    onClick={this.routeBack}>
                    {this.props.title}
                </button>
            </div>
        )
    }
}

export default withRouter(Ribbon)