const AppVersionsObject = [
    {
        name: 'Type',
        node: 'type',
        type: 'text',
        requ: true
    },
    {
        name: 'Version',
        node: 'version',
        type: 'text',
        requ: true
    }
]

export default AppVersionsObject