import React from 'react'
import { withRouter } from 'react-router-dom'
import Form from '../../modals/Form'
import BorrowCreditObject from '../../objects/BorrowCreditObject'
import API from '../../helpers/api'

class BorrowCreditView extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // api dataset
            dataset: null
        }
        // method binds
        this.callback = this.callback.bind(this)
    }
    // component on mount
    componentDidMount() {
        // get item id
        const item_id = this.props.match.params.id
        // request item data
        API.getBorrowCreditById(item_id).then(data => {
            // set dataset and stop loading
            this.setState({ dataset: data, loading: false })
        })
    }
    // method to callback
    callback(event) {
        // check event type
        if (event.type === 'close') {
            // navigate to table page
            this.props.history.push('../')
        }
    }
    // method to render
    render() {
        // return page dom
        return (
            <Form
                loading={this.state.loading}
                dataset={this.state.dataset}
                datamap={BorrowCreditObject}
                onevent={this.callback}
                disable={true}
            />
        )
    }
}

export default withRouter(BorrowCreditView)