import React from 'react'
import { withRouter } from 'react-router-dom'
import Form from '../../modals/Form'
import InfoPagesObject from '../../objects/InfoPagesObject'
import Confirm from '../../modals/Confirm'
import API from '../../helpers/api'
import { getImage } from '../../helpers/helpers'
import Alert from '../../modals/Alert'

class InfoPagesUpdate extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // api datamap
            datamap: InfoPagesObject,
            // api dataset
            dataset: null
        }
        // method binds
        this.callback = this.callback.bind(this)
    }
    // component on mount
    async componentDidMount() {
        // get item id
        const item_id = this.props.match.params.id
        // request item data
        API.getInfoPagesById(item_id).then(data => {
            // set dataset and stop loading
            this.setState({ dataset: data, loading: false })
        })
    }
    // method to callback
    callback(event) {
        // check event type
        if (event.type === 'submit') {
            // confirm submit
            Confirm('Are you sure to update this item?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // set loading
                    this.setState({ loading: true })
                    // get item id
                    const item_id = this.props.match.params.id
                    // request create
                    API.updateInfoPagesById(item_id, event.value).then(() => {
                        // stop loading
                        this.setState({ loading: false })
                        // destroy modal
                        data.target.destroy()
                        // navigate to table page
                        this.props.history.push('../')
                    }).catch(() => {
                        // stop loading
                        this.setState({ loading: false })
                        // destroy modal
                        data.target.destroy()
                    })
                } else {
                    // destroy modal
                    data.target.destroy()
                }
            })
        } else if (event.type === 'cancel') {
            // confirm cancel
            Confirm('Are you sure to discard changes?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // navigate to table page
                    this.props.history.push('../')
                }
                // destroy modal
                data.target.destroy()
            })
        } else if (event.type === 'update') {
            if (event.node === 'image') {
                // get image from file
                getImage(event.data).then(image => {
                    // check image size
                    if (image.width === 1180 && image.height === 664) {
                        // upload image
                        API.uploadFile(event.data).then(data => {
                            // set id on form
                            event.fill({ image: data.id })
                        })
                    } else {
                        // alert image size
                        Alert('Image size should be 1180px of width and 664px of height').then(data => {
                            // destroy modal
                            data.target.destroy()
                        })
                        // restore old image
                        event.fill({ image: event.old_data })
                    }
                })
            }
        }
    }
    // method to render
    render() {
        // return page dom
        return (
            <Form
                loading={this.state.loading}
                dataset={this.state.dataset}
                datamap={InfoPagesObject}
                onevent={this.callback}
            />
        )
    }
}

export default withRouter(InfoPagesUpdate)