import React from "react"
import "./Table.css"
import { getNestedvalue } from "../helpers/helpers"

// method to evaluate cell value
const getCellValue = (column, record, index, array) => {
    // check data type
    if (typeof column.data === 'function') {
        // return calculated value
        return column.data(record, index, array)
    } else if ('node' in column) {
        // return nested node value
        return getNestedvalue(record, column.node)
    } else {
        // return common value
        return column.data
    }
}

// method to get pagination array
const pagingArray = filters => {
    // pagination data
    const page = filters.page
    const limit = filters.limit
    const total = filters.total
    const items = Array.from('.'.repeat(Math.ceil(total / limit)))
    const length = items.length
    // pages array
    const pages = ['<']
    // check pagination size
    if (length < 6) {
        // small pagination
        items.map((_char, index) => pages.push(index + 1))
    } else {
        // check current page
        if (page < 5) {
            // front page
            pages.push(1, 2, 3, 4, 5, '...', length)
        } else if (page > length - 5) {
            // end page
            pages.push(
                1,
                '...',
                length - 4,
                length - 3,
                length - 2,
                length - 1,
                length
            )
        } else {
            // middle page
            pages.push(1, '...', page - 1, page, page + 1, '...', length)
        }
    }
    // next button
    pages.push('>')
    // return pages array
    return pages
}

export default class Table extends React.Component {

    // constructor
    constructor(props) {
        // super props
        super(props)
        const search = this.props.search
        // states
        this.state = {
            search: (search || '')
        }
    }

    // method to get header
    header() {
        // filters object
        const filters = this.props.filters
        // method to create
        const create = () => {
            // return if loading
            if (this.props.loading) { return }
            // callback event
            this.props.onevent({ type: 'create' })
        }
        // method to search
        const searchHadler = event => {
            // return if loading
            if (this.props.loading) { return }
            // check input type
            if (event.type === 'keydown' && event.key === 'Enter') {
                // return if same value
                if (filters.search === this.state.search) { return }
                // callback search
                this.props.onevent({
                    type: 'search',
                    value: { search: this.state.search, page: 1 }
                })
            } else if (event.type === 'click') {
                // check state with prop
                if (filters.search === this.state.search) {
                    // reset search
                    this.setState({ search: '' }, () => {
                        // callback search
                        this.props.onevent({
                            type: 'search',
                            value: { search: '', page: 1 }
                        })
                    })
                } else {
                    // return if same value
                    if (filters.search === this.state.search) { return }
                    // callback search
                    this.props.onevent({
                        type: 'search',
                        value: { search: this.state.search, page: 1 }
                    })
                }
            }
        }
        // get button state
        const bstate = filters.search !== this.state.search || filters.search === ''
        // return search dom
        return (
            <>
                <input
                    type="text"
                    className="table-modal-search-input"
                    placeholder="Search Items..."
                    value={this.state.search}
                    onChange={event => this.setState({ search: event.target.value })}
                    onKeyDown={event => searchHadler(event)}
                />
                <input
                    type="button"
                    className={'table-modal-search-button' + (bstate ? ' ready' : ' clear')}
                    onClick={event => searchHadler(event)}
                />
                <button
                    className="table-modal-create-button"
                    onClick={create}>
                    Create New Item
                </button>
            </>
        )
    }

    // method to get container
    container() {
        // return container dom
        return (
            <>
                <thead className="table-modal-container-head">
                    <tr>
                        {this.headers()}
                    </tr>
                </thead>
                <tbody className="table-modal-container-body">
                    {this.records()}
                </tbody>
            </>
        )
    }

    // method to get headers
    headers() {
        // columns array
        const columns = this.props.columns
        // filters object
        const filters = this.props.filters
        // return mapped columns
        return columns.map((item, index) => {
            // check sort column
            if ('sort' in item && filters !== undefined) {
                // sorted class
                const sorted = filters.sort === item.sort ? filters.sort_mode : ''
                // sort click event
                const sort = sort => {
                    // return if loading
                    if (this.props.loading) { return }
                    // callback sort
                    this.props.onevent({
                        type: 'sort',
                        value: {
                            sort: sort,
                            sort_mode: sort === item.sort
                                ? sorted === 'ASC' ? 'DESC' : 'ASC'
                                : 'ASC',
                            page: 1
                        }
                    })
                }
                // return sort header dom
                return (
                    <th
                        key={index}
                        scope="col"
                        className="sort"
                        align={item.side || 'center'}
                        onClick={() => sort(item.sort)}>
                        <span className={sorted}>
                            {item.name || item.sort || ''}
                        </span>
                    </th>
                )
            } else {
                // return empty header dom
                return (
                    <th key={index} align={item.side || 'center'}>
                        {item.name || ''}
                    </th>
                )
            }
        })
    }

    // method to get records
    records() {
        // records array
        const records = this.props.records
        // columns array
        const columns = this.props.columns
        // check empty records
        if (records.length === 0 && this.props.loading === false) {
            // return as results
            return (
                <tr className="empty">
                    <td align="center" colSpan={columns.length}>
                        No Results
                    </td>
                </tr>
            )
        } else if (records.length === 0 && this.props.loading === true) {
            // return as results
            return (
                <tr className="no-records">
                    <td align="center" colSpan={columns.length}>
                        Loading...
                    </td>
                </tr>
            )
        } else {
            // return mapped records
            return records.map((record, rindex) => (
                <tr key={rindex} className="record">
                    {
                        columns.map((column, cindex) => (
                            <td
                                key={cindex}
                                align={column.side || 'center'}
                                style={{ width: column.size ? column.size + 'px' : 'auto' }}>
                                {this.content(column, record, rindex, records)}
                            </td>
                        ))
                    }
                </tr>
            ))
        }
    }

    // method to get content
    content(column, record, index, array) {
        // get common data
        const data = getCellValue(column, record, index, array)
        // check column type
        if (column.type === 'text' || column.type === undefined) {
            // return common cell data
            return data
        } else if (column.type === 'button') {
            // click event
            const call = () => {
                // return if loading
                if (this.props.loading) { return }
                // callback event
                column.call(record)
            }
            // action button
            return (
                <button className="table-modal-control-button" onClick={call}>
                    {data}
                </button>
            )
        } else if (column.type === 'switch') {
            // click event
            const call = () => {
                // return if loading
                if (this.props.loading) { return }
                // callback event
                column.call(record, !data)
            }
            // switch button
            return (
                <button
                    className={"table-modal-control-switch" + (data ? ' on' : ' off')}
                    onClick={call}
                />
            )
        } else if (column.type === 'image') {
            // return image
            return (
                <img draggable={false} src={data} alt={''} />
            )
        }
    }

    // method to get footer
    footer() {
        // loading state
        const loading = this.props.loading
        // filters object
        const filters = this.props.filters
        // pages array
        const pages = pagingArray(filters)
        // method to change page
        const changePage = page => {
            // return if loading
            if (this.props.loading) { return }
            // callback page
            this.props.onevent({ type: 'page', value: { page } })
        }
        // map pages dom
        return pages.map((item, index) => {
            // check item
            if (typeof item === 'number') {
                if (item === filters.page) {
                    // current page
                    return (
                        <button key={index} className="table-modal-footer-button current">
                            {item}
                        </button>
                    )
                } else {
                    // other pages
                    return (
                        <button
                            key={index}
                            className="table-modal-footer-button"
                            onClick={() => changePage(item)}>
                            {item}
                        </button>
                    )
                }
            } else if (item === '...') {
                // dost space
                return (
                    <button key={index} className="table-modal-footer-button jump summary">
                        ...
                    </button>
                )
            } else if (item === '<') {
                // disabled state
                const disabled = filters.page === 1
                // last button
                return (
                    <button
                        key={index}
                        className="table-modal-footer-button jump previous"
                        disabled={loading || disabled}
                        onClick={() => changePage(filters.page - 1)}
                    />
                )
            } else if (item === '>') {
                // disabled state
                const disabled = filters.page > Math.ceil(filters.total / filters.limit) - 1
                // next button
                return (
                    <button
                        key={index}
                        className="table-modal-footer-button jump next"
                        disabled={loading || disabled}
                        onClick={() => changePage(filters.page + 1)}
                    />
                )
            } else {
                // invalid item
                return <></>
            }
        })
    }

    // method to render
    render() {
        // get loading state
        const loading = this.props.loading ? ' loading' : ''
        // return table dom
        return (
            <div className={'table-modal' + loading}>
                {
                    'filters' in this.props
                        ? <div className="table-modal-header">
                            {this.header()}
                        </div>
                        : <></>
                }
                <div className="table-modal-body">
                    <table className="table-modal-container" cellPadding={0} cellSpacing={0}>
                        {this.container()}
                    </table>
                </div>
                {
                    'filters' in this.props
                        ? <div className="table-modal-footer">
                            {this.footer()}
                        </div>
                        : <></>
                }
            </div>
        )
    }

}