import API from "./api"
import store, { updateStore } from "../store/store"

const Preloader = async (...items) => {
    // check for plan types
    if (items.includes('PlanTypes')) {
        // get plan types and map into dropdown options
        const PlanTypes = (await API.getPlanTypes()).data.map(item => {
            // return as dropdown object
            return {
                data: item.id,
                name: item.plan_type,
                mode: item.plan_type.includes('/') ? 'any' : item.id
            }
        })
        // dispatch plan types
        store.dispatch(updateStore({ key: 'PlanTypes', value: PlanTypes }))
    }
    // check for prepaid plans
    if (items.includes('PrepaidPlans')) {
        // get prepaid plans
        const PrepaidPlans = (await API.getPrepaidPlans({
            // get only required fields
            fields: ['id', 'plan_name', 'plan_type.plan_type', 'plan_type.id']
        })).data.map(item => {
            // return as dropdown object
            return {
                data: item.id,
                name: item.plan_name,
                type: item.plan_type ? item.plan_type.id : null
            }
        })
        // dispatch prepaid plans
        store.dispatch(updateStore({ key: 'PrepaidPlans', value: PrepaidPlans }))
    }
    // check for postpaid plans
    if (items.includes('PostpaidPlans')) {
        // get postpaid plans
        const PostpaidPlans = (await API.getPostpaidPlans({
            // get only required fields
            fields: ['id', 'plan_name', 'plan_type.plan_type', 'plan_type.id']
        })).data.map(item => {
            // return as dropdown object
            return {
                data: item.id,
                name: item.plan_name,
                type: item.plan_type ? item.plan_type.id : null
            }
        })
        // dispatch postpaid plans
        store.dispatch(updateStore({ key: 'PostpaidPlans', value: PostpaidPlans }))
    }
    // check for faq types
    if (items.includes('FAQTypes')) {
        // get question types and map into dropdown options
        const FAQTypes = (await API.getFAQTypes()).data.map(item => {
            // return as dropdown object
            return { data: item.id, name: item.type }
        })
        // dispatch faq types
        store.dispatch(updateStore({ key: 'FAQTypes', value: FAQTypes }))
    }
}

export default Preloader