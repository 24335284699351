import { getDropdownValue } from "../helpers/helpers"

const PromotionsObject = [
    {
        name: 'Header',
        node: 'header',
        type: 'text',
        requ: true
    },
    {
        name: 'Account Type',
        node: 'account_type',
        type: 'dropdown',
        data: 'PREPAID',
        list: [
            { name: 'Prepaid', data: 'PREPAID' },
            { name: 'Postpaid', data: 'POSTPAID' }
        ]
    },
    {
        type: 'separator'
    },
    {
        name: 'Description',
        node: 'description',
        type: 'textarea'
    },
    {
        name: 'Image',
        node: 'image',
        type: 'image',
        data: { id: '' },
        hint: '(1180 x 664)',
        bind: {
            set: data => data ? data.id : '',
            get: data => data ? ({ id: data }) : null
        }
    },
    {
        type: 'separator'
    },
    {
        name: 'Promotion Type',
        node: 'promo_type',
        type: 'dropdown',
        list: [],
        bind: {
            set: getDropdownValue,
            get: (data, mdata) => ({ id: getDropdownValue(data, mdata) })
        }
    },
    {
        name: 'Reference',
        node: 'reference',
        type: 'dropdown',
        list: [],
        bind: { set: getDropdownValue, get: getDropdownValue }
    },
    {
        name: 'Redirect',
        node: 'redirect',
        type: 'text'
    },
    {
        type: 'separator'
    },
    {
        name: 'Active',
        node: 'status',
        type: 'switch',
        data: 'Active',
        bind: {
            set: data => data === 'Active',
            get: data => data ? 'Active' : 'Inactive'
        }
    }
]

export default PromotionsObject