const AssetItemsObject = [
    {
        name: 'Key',
        node: 'key',
        type: 'text',
        requ: true
    },
    {
        name: 'Value',
        node: 'value',
        type: 'text',
        requ: true
    }
]

export default AssetItemsObject