import React from 'react'
import { withRouter } from 'react-router-dom'

import Form from '../../modals/Form'
import FAQObject from '../../objects/FAQObject'

import API from '../../helpers/api'
import Preloader from '../../helpers/preloader'
import { updateDropdownList } from '../../helpers/helpers'

class FAQView extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // form datamap
            datamap: FAQObject,
            // api dataset
            dataset: null
        }
        // method binds
        this.callback = this.callback.bind(this)
    }
    // component on mount
    async componentDidMount() {
        // preload faq types
        await Preloader('FAQTypes')
        // load dropdown values
        updateDropdownList(FAQObject, 'type', 'FAQTypes')
        // get item id
        const item_id = this.props.match.params.id
        // request item data
        API.getFAQById(item_id).then(data => {
            // set dataset and stop loading
            this.setState({ dataset: data, datamap: FAQObject, loading: false })
        })
    }
    // method to callback
    callback(event) {
        // check event type
        if (event.type === 'close') {
            // navigate to table page
            this.props.history.push('../')
        }
    }
    // method to render
    render() {
        // return page dom
        return (
            <Form
                loading={this.state.loading}
                dataset={this.state.dataset}
                datamap={this.state.datamap}
                onevent={this.callback}
                disable={true}
            />
        )
    }
}

export default withRouter(FAQView)