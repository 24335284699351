import { getDropdownValue } from "../helpers/helpers"

const CarouselItemsObject = [
    {
        name: 'Action',
        node: 'action',
        type: 'dropdown',
        list: [],
        bind: {
            set: getDropdownValue,
            get: (data, mdata) => ({ id: getDropdownValue(data, mdata) })
        }
    },
    {
        name: 'Reference',
        node: 'reference',
        type: 'dropdown',
        list: [],
        bind: {
            set: getDropdownValue,
            get: (data, mdata) => ({ id: getDropdownValue(data, mdata) })
        }
    },
    {
        type: 'separator'
    },
    {
        name: 'Image',
        node: 'image',
        type: 'image',
        data: { id: '' },
        hint: '(1180 x 664)',
        bind: {
            set: data => data ? data.id : '',
            get: data => data ? ({ id: data }) : null
        }
    },
    {
        type: 'separator'
    },
    {
        name: 'Active',
        node: 'status',
        type: 'switch',
        data: 'Active',
        bind: {
            set: data => data === 'Active',
            get: data => data ? 'Active' : 'Inactive'
        }
    }
]

export default CarouselItemsObject