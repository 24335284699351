import { getDropdownValue } from "../helpers/helpers"

const FAQObject = [
    {
        name: 'Question',
        node: 'question',
        type: 'text',
        requ: true
    },
    {
        name: 'Answer',
        node: 'answer',
        type: 'html'
    },
    {
        name: 'Type',
        node: 'type',
        type: 'dropdown',
        list: [],
        bind: {
            set: getDropdownValue,
            get: (data, mdata) => ({ id: getDropdownValue(data, mdata) })
        }
    },
    {
        type: 'separator'
    },
    {
        name: 'Active',
        node: 'status',
        type: 'switch',
        data: 'Active',
        bind: {
            set: data => data === 'Active',
            get: data => data ? 'Active' : 'Inactive'
        }
    }
]

export default FAQObject