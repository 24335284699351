const LocationsObject = [
    {
        name: 'Name',
        node: 'name',
        type: 'text',
        requ: true
    },
    {
        name: 'Address',
        node: 'address',
        type: 'text'
    },
    {
        name: 'Telephone',
        node: 'telephone',
        type: 'text'
    },
    {
        type: 'separator'
    },
    {
        name: 'Coordinates',
        node: 'coordinates',
        type: 'map',
        data: { latitude: '-6.314992', longitude: '143.955550' }
    },
    {
        type: 'separator'
    },
    {
        name: 'Start Time',
        node: 'start_time',
        type: 'time'
    },
    {
        name: 'End Time',
        node: 'end_time',
        type: 'time'
    },
    {
        type: 'separator'
    },
    {
        name: 'Active',
        node: 'status',
        type: 'switch',
        data: 'Active',
        bind: {
            set: data => data === 'Active',
            get: data => data ? 'Active' : 'Inactive'
        }
    }
]

export default LocationsObject