import { toQueryParams } from "./api"
import store from "../store/store"

// method to set data on local storage
export const setLocal = (key, data) => {
	// get stringified data
	sessionStorage.setItem(key, JSON.stringify(data))
}

// method to get data from local storage
export const getLocal = (key, def = null) => {
	if (key in sessionStorage) {
		// get data from storage
		const data = JSON.parse(sessionStorage[key])
		// check null state
		if (data !== null) {
			// return data
			return data
		} else {
			// return default data
			return def
		}
	} else {
		// return default data
		return def
	}
}

// method to update nested states
export const updateStates = (that, input, value) => {
	// return promise
	return new Promise(async resolve => {
		// if value given
		if (value !== undefined) {
			// get current states
			const current = that.state[input]
			// check for object
			if (typeof current === 'object' && current !== null && !Array.isArray(current)) {
				// source object
				const object = {}
				// assign current states
				object[input] = Object.assign(current, value)
				// update states
				that.setState(object, resolve)
			} else {
				// source object
				const object = {}
				// set value on object
				object[input] = value
				// update states
				that.setState(object, resolve)
			}
			// callback resolve
			resolve()
		} else {
			// get keys of object
			const keys = Object.keys(input)
			// for each key
			for (let i = 0; i < keys.length; i++) {
				// current key
				const key = keys[i]
				// current value
				const val = input[key]
				// if value available
				if (val !== undefined) {
					// update value
					await that.update(key, val)
				}
			}
			// callback resolve
			resolve()
		}
	})
}

// method to get nested value from object
export const getNestedvalue = (object = {}, path = '') => {
	if (path.includes('.')) {
		const nodes = path.split('.')
		for (let i = 0; i < nodes.length; i++) {
			const node = nodes[i]
			if (node in object) {
				object = object[node]
			} else {
				return object
			}
		}
		return object
	} else {
		return object[path]
	}
}

// method to create new element
export const createElement = (name, content, tag) => {
	// create root element
	const element = document.createElement(tag || 'div')
	// set class name
	if (name) { element.className = name }
	// check content
	if (typeof content === 'object') {
		// check array
		if (Array.isArray(content)) {
			// for each element
			for (let i = 0; i < content.length; i++) {
				// append current child
				element.appendChild(content[i])
			}
		} else {
			// append as child element
			element.appendChild(content)
		}
	} else if (content !== undefined) {
		// set as inner content
		element.innerHTML = content
	}
	// return element
	return element
}

// method to get english lang value from api data
export const en = array => {
	// check array length
	if (array.length > 0) {
		// return matched item or first item
		return array.find(item => item.language === 'EN' || item.languge === 'EN') || array[0]
	} else {
		// return no item
		return {}
	}
}

// method to get asset url from api data
export const asset = (file, query = {}) => {
	// return blank image if invalid
	if (!file) { return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==' }
	// get doman root
	const root = process.env.REACT_APP_API_URL
	// return asset path
	return root + '/assets/' + file.id + '?' + toQueryParams(query)
}

// method to ellipsis text by length
export const ellipsis = (text, length, slice = false) => {
	// return if invalid input
	if (text === null || text === undefined) { return '' }
	// convert to string
	text = text.toString()
	// replace urls from text
	const header = text.substring(0, length)
	// check header text
	if (text[length] === undefined) {
		// smaller than length
		return header
	} else if (!slice && text[length] === ' ') {
		// no word break
		return header.substring(0, Math.min(header.length, header.lastIndexOf(' '))) + '...'
	} else if (!slice) {
		// remove word break
		return header.substring(0, Math.min(header.length, header.lastIndexOf(' '))) + '...'
	} else {
		// remove word break
		return header + '...'
	}
}

// method to get page class by api data path
export const getPageClass = (route = '', path = '') => {
	// page class name
	let name = route + path
	// remove params
	name = name.split(':')[0]
	// remove slashes
	name = name.replace(/\//g, '-')
	// trim dashes
	name = name.split('-').filter(x => x !== '').join('-')
	// return class name
	return path === ''
		? 'page-inner ' + name
		: 'page-inner ' + route.replace(/\//g, '') + ' ' + name
}

// method to read image file
export const getImage = file => {
	// return promise
	return new Promise(resolve => {
		// create file reader
		const read = new FileReader()
		// on file read
		read.onload = () => {
			// create image
			const img = new Image()
			// on file load
			img.onload = () => {
				// resolve image
				resolve(img)
			};
			// set file url data
			img.src = read.result
		};
		// read file
		read.readAsDataURL(file)
	})
}

// method to get form dropdown value
export const getDropdownValue = (data, mdata) => {
	// check input values
	if (data) {
		// check id node
		if (typeof data === 'object' && data !== null && 'id' in data) {
			// return data id
			return data.id
		} else {
			// return data
			return data
		}
	} else if (mdata && mdata.list.length > 0) {
		// return first item as default value
		return mdata.list[0].data
	} else {
		// return null
		return null
	}
}

// method to load dropdown list from store
export const updateDropdownList = (object, node, list) => {
	// get items array
	const array = store.getState()[list]
	// find object item by node
	const item = object.find(item => item.node === node)
	// update list
	item.list = array
	// return array
	return array
}

// method to filter plans by type
export const getPlansByType = (plans, type = null) => {
	// get type id if an object
	if (typeof type === 'object' && type !== null) { type = type.id }
	// get plan type item
	const item = store.getState().PlanTypes.find(item => {
		// get default or by given id
		return type === null || type === '' || item.data.toString() === type.toString()
	})
	// get plan type by code
	const plan = plans === 'PREPAID' ? 'PrepaidPlans' : plans === 'POSTPAID'
		// get plan type as given
		? 'PostpaidPlans' : plans
	// get plans array
	const list = store.getState()[plan].filter(plan => {
		// return all if any mode or filter by type
		return item.mode === 'any' || item.data.toString() === plan.type.toString()
	})
	// return list
	return list
}