const BorrowCreditObject = [
    {
        name: 'Description',
        node: 'description',
        type: 'textarea',
        requ: true
    },
    {
        type: 'separator'
    },
    {
        name: 'Amount',
        node: 'amount',
        type: 'number',
        unit: 'VT',
        requ: true
    },
    {
        name: 'Currency',
        node: 'currency',
        type: 'hidden',
        data: 'VT'
    },
    {
        type: 'separator'
    },
    {
        name: 'Active',
        node: 'status',
        type: 'switch',
        data: 'Active',
        bind: {
            set: data => data === 'Active',
            get: data => data ? 'Active' : 'Inactive'
        }
    }
]

export default BorrowCreditObject