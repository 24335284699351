import React from 'react'
// main pages
import Home from './component/Home'
import Login from './component/Login'
// helpers
import { getLocal } from './helpers/helpers'
// themes
import "./themes.css"

// home component
export default class App extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // logged state
        this.state = { logged: false }
    }
    // on component mount
    async componentDidMount() {
        // get udata data
        const udata = getLocal('user-data', null)
        // check data availability
        if (udata) {
            // set logged state
            this.setState({ logged: true })
        }
    }
    // method to render
    render() {
        // switch screens by logged state
        return (
            <div id="app-root" data-theme="classic">
                {this.state.logged ? <Home /> : <Login />}
            </div>
        )
    }
}
