import React from 'react'
import { withRouter } from 'react-router-dom'

import Form from '../../modals/Form'
import PromotionsObject from '../../objects/PromotionsObject'

import API from '../../helpers/api'
import Preloader from '../../helpers/preloader'
import { getPlansByType, updateDropdownList } from '../../helpers/helpers'

class PromotionsView extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // api datamap
            datamap: PromotionsObject,
            // api dataset
            dataset: null
        }
        // method binds
        this.callback = this.callback.bind(this)
    }
    // component on mount
    async componentDidMount() {
        // preload plan types
        await Preloader('PlanTypes', 'PrepaidPlans', 'PostpaidPlans')
        // get item id
        const item_id = this.props.match.params.id
        // request item data
        API.getPromotionsById(item_id).then(data => {
            // load dropdown values
            updateDropdownList(PromotionsObject, 'promo_type', 'PlanTypes')
            // get plan list
            const list = getPlansByType(data.account_type, data.promo_type)
            // update object for reference
            PromotionsObject.find(item => item.node === 'reference').list = list
            // set dataset and stop loading
            this.setState({ dataset: data, datamap: PromotionsObject, loading: false })
        })
    }
    // method to callback
    callback(event) {
        // check event type
        if (event.type === 'close') {
            // navigate to table page
            this.props.history.push('../')
        }
    }
    // method to render
    render() {
        // return page dom
        return (
            <Form
                loading={this.state.loading}
                dataset={this.state.dataset}
                datamap={this.state.datamap}
                onevent={this.callback}
                disable={true}
            />
        )
    }
}

export default withRouter(PromotionsView)