import React from 'react'
import { withRouter } from 'react-router-dom'
import API from '../../helpers/api'
import { ellipsis, updateStates } from '../../helpers/helpers'
import Table from '../../modals/Table'
import Confirm from '../../modals/Confirm'

class PrepaidPlans extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // table records
            records: [],
            // record filters
            filters: {
                fields: [
                    'id',
                    'plan_name',
                    'plan_type.plan_type',
                    'plan_group',
                    'status'
                ],
                search: '',
                page: 1,
                limit: 8,
                sort: 'id',
                sort_mode: 'ASC'
            }
        }
        // method binds
        this.reviewItem = this.reviewItem.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.switchItem = this.switchItem.bind(this)
    }
    // method to update state
    async update(input, value) {
        // update nested states
        await updateStates(this, input, value)
    }
    // load table on mount
    componentDidMount() { this.loadTable() }
    // method to load table 
    async loadTable(event = null) {
        // check event type
        if (event && event.type === 'create') {
            // navigate to item create
            this.props.history.push(this.props.path + '/create/')
        } else {
            // set loading flag
            await this.update({ loading: true })
            // check event
            if (event) {
                // update filters
                await this.update({ filters: event.value })
            }
            // load prepaid table data
            const data = await API.getPrepaidPlans(this.state.filters)
            // update records and states
            await this.update({
                loading: false,
                records: data.data,
                filters: { total: data.meta.filter_count }
            })
        }
    }
    // method to review item
    reviewItem(item) {
        // navigate to item view
        this.props.history.push(this.props.path + '/view/' + item.id)
    }
    // method to update item
    updateItem(item) {
        // navigate to item edit
        this.props.history.push(this.props.path + '/edit/' + item.id)
    }
    // method to delete item
    deleteItem(item) {
        // request user confirmation
        Confirm('Are you sure you want to delete this item?').then(async data => {
            // lock modal
            data.target.lock()
            // check response
            if (data.response === 'action') {
                // set loading flag
                await this.update({ loading: true })
                // request item delete
                await API.deletePrepaidPlans(item.id)
                // destroy modal
                data.target.destroy()
                // reset page
                await this.update({ filters: { page: 1 } })
                // reload table
                await this.loadTable()
                // reset loading flag
                await this.update({ loading: false })
            } else {
                // destroy modal
                data.target.destroy()
            }
        })
    }
    // method to switch item
    async switchItem(item, value) {
        // set loading flag
        await this.update({ loading: true })
        // get new status
        const status = value ? 'Active' : 'Inactive'
        // get all items
        const items = this.state.records
        // get item by id
        const rdata = items.find(record => record.id === item.id)
        // switch record status
        if (rdata) { rdata.status = status }
        // update records
        await this.update({ records: items })
        // request item status update
        await API.setPrepaidPlansStatus(item.id, status)
        // reload table
        await this.loadTable()
        // reset loading flag
        await this.update({ loading: false })
    }
    // method to render
    render() {
        // return page dom
        return (
            <Table
                loading={this.state.loading}
                records={this.state.records}
                filters={this.state.filters}
                onevent={event => this.loadTable(event)}
                columns={[
                    {
                        name: 'Id',
                        type: 'text',
                        data: item => item.id,
                        sort: 'id',
                        size: 80
                    },
                    {
                        name: 'Name',
                        type: 'text',
                        data: item => ellipsis(item.plan_name, 50),
                        side: 'left',
                        sort: 'plan_name'
                    },
                    {
                        name: 'Type',
                        type: 'text',
                        data: item => ellipsis(item.plan_type ? item.plan_type.plan_type : '', 50),
                        side: 'left',
                        sort: 'plan_type'
                    },
                    {
                        name: 'Group',
                        type: 'text',
                        data: item => ellipsis(item.plan_group, 50),
                        side: 'left',
                        sort: 'plan_group'
                    },
                    {
                        name: 'Active',
                        type: 'switch',
                        data: item => item.status === 'Active',
                        sort: 'status',
                        call: this.switchItem,
                        size: 80
                    },
                    {
                        type: 'button',
                        data: 'View',
                        call: this.reviewItem,
                        size: 80
                    },
                    {
                        type: 'button',
                        data: 'Update',
                        call: this.updateItem,
                        size: 80
                    },
                    {
                        type: 'button',
                        data: 'Delete',
                        call: this.deleteItem,
                        size: 80
                    }
                ]}
            />
        )
    }
}

export default withRouter(PrepaidPlans)