import React from 'react'
import { Link } from 'react-router-dom'
import './SideMenu.css'

// side menu component
export default class SideMenu extends React.Component {
    // get menu list
    list() {
        // return mapped routes
        return this.props.list.map((item, index) => {
            // check with role
            if (item.roles.includes(this.props.role)) {
                // check active state
                const active = this.props.path === item.route
                // return menu item
                return (
                    <Link
                        to={item.route}
                        key={index}
                        className="side-menu-item"
                        data-icon={item.route + (active ? '-fill' : '')}
                        data-active={active}
                        onClick={() => this.props.call(item.route)}>
                        {item.label}
                    </Link>
                )
            } else {
                // return hidden item
                return <span key={index}></span>
            }
        })
    }
    // method to render
    render() {
        return (
            <div className="side-menu">
                <div className="side-menu-logo">
                    <div className='side-menu-logo-title'>
                        Admin Portal
                    </div>
                    <div className='side-menu-logo-sub-title'>
                        Vodafone Vanuatu
                    </div>
                </div>
                <div className="side-menu-list">
                    {this.list()}
                </div>
            </div>
        )
    }
}