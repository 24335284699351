import { createElement } from "../helpers/helpers"
import "./Alert.css"

// method to alert action popup
const Alert = (message, action_text = 'OK') => {
    // action buttons
    const action = createElement('alert-modal-button action', action_text, 'button')
    // close button
    const close = createElement('alert-modal-close', '×')
    // create inner elements
    const inner = createElement('alert-modal-container', [
        createElement('alert-modal-container-header', close),
        createElement('alert-modal-container-message', message || 'Are you sure?'),
        createElement('alert-modal-container-buttons', [action]),
    ])
    const bkdrp = createElement('alert-modal-backdrop')
    // create modal
    const modal = createElement('alert-modal', [bkdrp, inner])
    // states
    const states = { locked: false }
    // response data
    const data = {
        // response value
        response: null,
        // target methods
        target: {
            // method to destroy
            destroy: () => {
                // close animation
                modal.classList.add('closed')
                // delete modal
                setTimeout(() => modal.outerHTML = '', 350)
                // global unlock
                global.alert_lock = false
            },
            // method to lock modal
            lock: () => {
                // locked states
                states.locked = true
                // add locked styles
                modal.classList.add('locked')
            },
            // method to unlock modal
            unlock: () => {
                // reset locked states
                states.locked = false
                // remove locked styles
                modal.classList.remove('locked')
            }
        }
    }
    // return promise
    return new Promise(resolve => {
        // check global lock
        if (global.alert_lock) {
            // return if locked
            return
        } else {
            // global lock
            global.alert_lock = true
        }
        // append to document
        document.querySelector('.page-inner').appendChild(modal)
        // action response
        action.addEventListener('click', () => {
            // return if locked
            if (states.locked) { return }
            // set response value
            data.response = 'action'
            // resolve data
            resolve(data)
        })
        // backdrop response
        bkdrp.addEventListener('click', () => {
            // return if locked
            if (states.locked) { return }
            // set response value
            data.response = 'cancel'
            // resolve data
            resolve(data)
        })
        // close response
        close.addEventListener('click', () => {
            // return if locked
            if (states.locked) { return }
            // set response value
            data.response = 'cancel'
            // resolve data
            resolve(data)
        })
    })
}

export default Alert