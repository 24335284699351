import React from 'react'
import { withRouter } from 'react-router-dom'

import Form from '../../modals/Form'
import Confirm from '../../modals/Confirm'
import PrepaidPlansObject from '../../objects/PrepaidPlansObject'

import API from '../../helpers/api'
import Preloader from '../../helpers/preloader'
import { updateDropdownList } from '../../helpers/helpers'

class PrepaidPlansUpdate extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            // loading state
            loading: true,
            // form datamap
            datamap: PrepaidPlansObject,
            // api dataset
            dataset: null
        }
        // method binds
        this.callback = this.callback.bind(this)
    }
    // component on mount
    async componentDidMount() {
        // preload plan types
        await Preloader('PlanTypes')
        // load dropdown values
        updateDropdownList(PrepaidPlansObject, 'plan_type', 'PlanTypes')
        // get item id
        const item_id = this.props.match.params.id
        // request item data
        API.getPrepaidPlansById(item_id).then(data => {
            // set dataset and stop loading
            this.setState({ dataset: data, datamap: PrepaidPlansObject, loading: false })
        })
    }
    // method to callback
    callback(event) {
        // check event type
        if (event.type === 'submit') {
            // confirm submit
            Confirm('Are you sure to update this item?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // set loading
                    this.setState({ loading: true })
                    // get item id
                    const item_id = this.props.match.params.id
                    // request create
                    API.updatePrepaidPlansById(item_id, event.value).then(() => {
                        // stop loading
                        this.setState({ loading: false })
                        // destroy modal
                        data.target.destroy()
                        // navigate to table page
                        this.props.history.push('../')
                    }).catch(() => {
                        // stop loading
                        this.setState({ loading: false })
                        // destroy modal
                        data.target.destroy()
                    })
                } else {
                    // destroy modal
                    data.target.destroy()
                }
            })
        } else if (event.type === 'cancel') {
            // confirm cancel
            Confirm('Are you sure to discard changes?').then(async data => {
                // lock modal
                data.target.lock()
                // check response
                if (data.response === 'action') {
                    // navigate to table page
                    this.props.history.push('../')
                }
                // destroy modal
                data.target.destroy()
            })
        }
    }
    // method to render
    render() {
        // return page dom
        return (
            <Form
                loading={this.state.loading}
                dataset={this.state.dataset}
                datamap={this.state.datamap}
                onevent={this.callback}
            />
        )
    }
}

export default withRouter(PrepaidPlansUpdate)