import React, { useState, useRef } from "react"
import "./Form.css"
import { asset } from "../helpers/helpers"
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'

const importObject = (schema = [], object = {}) => {
    // output object
    const output = {}
    // for each schema item
    for (let i = 0; i < schema.length; i++) {
        // current schema
        const map = schema[i]
        // current node
        const key = map.node
        // check node
        if ('node' in map) {
            // bind method
            const set = data => map.bind && map.bind.set
                ? map.bind.set(data, map)
                : data
            // set key
            const set_key = typeof key === 'object' ? key.set : key
            // set key
            const get_key = typeof key === 'object' ? key.get : key
            // object value
            const val = set(object[get_key])
            // default value
            const def = set(map.data)
            // check schema type
            if (map.type === 'array') {
                // get array data
                const arr = (val !== undefined ? val : def) || []
                // map array data
                output[set_key] = arr.map(item => {
                    // import each item
                    return importObject(map.list, item)
                })
            } else if (map.type === 'object') {
                // set object data
                output[set_key] = importObject(map.list, object[get_key] || map.data)
            } else if (map.type === 'checkbox' || map.type === 'switch') {
                // set node data
                output[set_key] = (val !== undefined ? val : def) || false
            } else {
                // set node data
                output[set_key] = (val !== undefined ? val : def) || ''
            }
        }
    }
    // return output
    return output
}

const exportObject = (schema = [], object = {}, inputs = {}) => {
    // output object
    const output = {}
    // for each schema item
    for (let i = 0; i < schema.length; i++) {
        // current schema
        const map = schema[i]
        // current node
        const key = map.node
        // check node
        if ('node' in map) {
            // set key
            const get_key = typeof key === 'object' ? key.get : key
            // inputs value
            const inp = inputs && inputs[get_key]
            // bind method
            const get = data => map.bind && map.bind.get
                ? map.bind.get(data, map, inp)
                : data
            // set key
            const set_key = typeof key === 'object' ? key.set : key
            // object value
            const val = get(object[set_key], inp)
            // check schema type
            if (map.type === 'array') {
                // get array data
                const arr = val || []
                // map array data
                output[get_key] = arr.map((item, index) => {
                    // export data
                    const exp = exportObject(map.list, item, inp)
                    // check map method
                    if (map.bind && map.bind.map) {
                        // return mapped data
                        return map.bind.map(exp, inp ? inp[index] : null, index)
                    } else {
                        // return without mapping
                        return exp
                    }
                })
            } else if (map.type === 'object') {
                // export data
                const exp = exportObject(map.list, object[set_key], inp)
                // check map method
                if (map.bind && map.bind.map) {
                    // set mapped data
                    output[get_key] = map.bind.map(exp, inp)
                } else {
                    // set without mapping
                    output[get_key] = exp
                }
            } else {
                // set node data
                output[get_key] = val
            }
        }
    }
    // return output
    return output
}

// method to get object value from path
const getObjectValue = (obj = {}, path = '') => {
    // check nested path
    if (Array.isArray(path) || path.includes('.')) {
        // split path to nodes
        const nodes = Array.isArray(path) ? path : path.split('.')
        // for each node
        for (let i = 0; i < nodes.length; i++) {
            // check child node
            if (obj !== null && obj !== undefined && nodes[i] in obj) {
                // jump to child node
                obj = obj[nodes[i]]
            } else {
                // return remain
                return obj
            }
        }
        // return value
        return obj
    } else {
        // return root value
        return obj[path]
    }
}

// method to set object value from path
const setObjectValue = (obj = {}, path = '', data = null) => {
    // check nested path
    if (Array.isArray(path) || path.includes('.')) {
        // split path to nodes
        const nodes = Array.isArray(path) ? path : path.split('.')
        // for each node
        for (let i = 0; i < nodes.length - 1; i++) {
            // check child node
            if (obj !== null && obj !== undefined && nodes[i] in obj) {
                // jump to child node
                obj = obj[nodes[i]]
            } else {
                // return failed
                return false
            }
        }
        // set data on last object node
        obj[nodes[nodes.length - 1]] = data
    } else {
        // set root value
        obj[path] = data
    }
}

// google map 
const FormMap = GoogleApiWrapper({
    apiKey: (`${process.env.REACT_APP_MAP_KEY}`)
})(class GoogleMap extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // method binds
        this.callback = this.callback.bind(this)
    }
    // method to callback
    callback(_t, _m, c) {
        // return if disabled
        if (this.props.disabled) { return }
        // update coords
        this.props.onChange({
            latitude: c.latLng.lat(),
            longitude: c.latLng.lng()
        })
    }
    // method to render
    render() {
        // check loading
        if (this.props.loading) {
            // return empty
            return <></>
        } else {
            // get coordinates
            const lat = this.props.coords.latitude
            const lng = this.props.coords.longitude
            // return map dom
            return (
                <Map zoom={6}
                    google={this.props.google}
                    initialCenter={{ lat, lng }}
                    center={{ lat, lng }}>
                    <Marker
                        position={{ lat, lng }}
                        draggable={!this.props.disabled}
                        onDragend={this.callback}
                    />
                </Map>
            )
        }
    }
})

const FormHTML = props => {
    // deafult value ref
    const value = useRef(props.value)
    // initial update state
    const [updated, setUpdated] = useState(false)
    // input callback
    const onInput = (event) => {
        // if on change
        if (props.onChange) {
            // callback inner html
            props.onChange(event.target.innerHTML)
            // set as updated
            setUpdated(true)
        }
    }
    // replace value if not updated yet
    if (!updated) { value.current = props.value }
    // return editable view
    return (
        <div
            contentEditable={!props.disabled}
            className="form-modal-item-control html"
            onInput={onInput}
            dangerouslySetInnerHTML={{ __html: value.current }}
        />
    )
}

export default class Form extends React.Component {

    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {}
        // reference elements
        this.form = React.createRef()
        this.imageInput = React.createRef()
        // method binds
        this.update = this.update.bind(this)
        this.pushItem = this.pushItem.bind(this)
        this.deltItem = this.deltItem.bind(this)
        this.submit = this.submit.bind(this)
        this.cancel = this.cancel.bind(this)
        this.close = this.close.bind(this)
        // updated state
        this.updated = false
    }

    // on component mount
    componentDidMount() {
        // get data schema
        const schema = this.props.datamap || []
        // get data object
        const object = this.props.dataset || {}
        // get form data states
        const data = importObject(schema, object)
        // set states
        this.setState(data)
    }

    componentDidUpdate() {
        // get data schema
        const schema = this.props.datamap
        // get data object
        const object = this.props.dataset
        // check object and updated state
        if (object && !this.props.loading && !this.updated) {
            // get form data states
            const data = importObject(schema, object)
            // set states
            this.setState(data)
            // set updated state
            this.updated = true
        }
    }

    // method to update form data
    update(path = '', data) {
        // return if disable
        if (this.props.disable) { return }
        // get states
        const states = this.state
        // get old data
        const old_data = getObjectValue(this.state, path)
        // set nested value
        setObjectValue(this.state, path, data)
        // update states
        this.setState(states, () => {
            // callback update
            this.props.onevent({
                type: 'update',
                node: path,
                data: data,
                old_data,
                form: states,
                fill: (data, then) => {
                    this.setState(data, then)
                }
            })
        })
    }

    // method to push array item
    pushItem(path, nodes) {
        // return if loading
        if (this.props.loading) { return }
        // get array
        const list = getObjectValue(this.state, path)
        // create object item
        const item = {}
        // for each node
        for (let i = 0; i < nodes.length; i++) {
            // current node
            const node = nodes[i]
            // set default value
            item[node.node] = node.data || ''
        }
        // push item into array
        list.push(item)
        // update states
        this.update(path, list)
    }

    // method to delete array item
    deltItem(path, index) {
        // return if loading
        if (this.props.loading) { return }
        // get array and filter by index
        const list = getObjectValue(this.state, path).filter((_data, indx) => {
            // filter only not index
            return indx !== index
        })
        // update states
        this.update(path, list)
    }

    // method to generate each element
    element(map, path, key) {
        // return for separator
        if (map.type === 'separator') {
            return (
                <div className="form-modal-line" key={key}>
                    <hr />
                </div>
            )
        }
        // get value by path
        const value = getObjectValue(this.state, path)
        // disabled flag
        const disabled = this.props.loading || this.props.disable || map.lock
        // return for invalid value
        if (map.type !== 'dropdown' && (value === null || value === undefined)) {
            // return empty
            return undefined
        }
        // check element type
        if (map.type === 'text') {
            return (
                <div className="form-modal-item text" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                    </div>
                    <div className="form-modal-item-input text">
                        <span
                            className="form-modal-item-unit"
                            hidden={map.unit === undefined}>
                            {map.unit}
                        </span>
                        <input
                            type="text"
                            required={map.requ}
                            disabled={disabled}
                            data-unit={map.unit}
                            className="form-modal-item-control text"
                            placeholder={map.hint || ''}
                            onChange={e => this.update(path, e.target.value)}
                            value={value || ''}
                        />
                    </div>
                </div>
            )
        } else if (map.type === 'textarea') {
            return (
                <div className="form-modal-item textarea" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                    </div>
                    <div className="form-modal-item-input textarea">
                        <textarea
                            required={map.requ}
                            disabled={disabled}
                            className="form-modal-item-control textarea"
                            placeholder={map.hint || ''}
                            onChange={e => this.update(path, e.target.value)}
                            value={value || ''}
                        ></textarea>
                    </div>
                </div>
            )
        } else if (map.type === 'email') {
            return (
                <div className="form-modal-item email" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                    </div>
                    <div className="form-modal-item-input email">
                        <span
                            className="form-modal-item-unit"
                            hidden={map.unit === undefined}>
                            {map.unit}
                        </span>
                        <input
                            type="email"
                            required={map.requ}
                            disabled={disabled}
                            min={0}
                            data-unit={map.unit}
                            className="form-modal-item-control email"
                            placeholder={map.hint || ''}
                            onChange={e => this.update(path, e.target.value)}
                            value={value || ''}
                        />
                    </div>
                </div>
            )
        } else if (map.type === 'number') {
            return (
                <div className="form-modal-item number" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                    </div>
                    <div className="form-modal-item-input number">
                        <span
                            className="form-modal-item-unit"
                            hidden={map.unit === undefined}>
                            {map.unit}
                        </span>
                        <input
                            type="number"
                            required={map.requ}
                            disabled={disabled}
                            min={0}
                            data-unit={map.unit}
                            className="form-modal-item-control number"
                            placeholder={map.hint || ''}
                            onChange={e => this.update(path, e.target.value)}
                            value={value || ''}
                        />
                    </div>
                </div>
            )
        } else if (map.type === 'time') {
            return (
                <div className="form-modal-item time" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                    </div>
                    <div className="form-modal-item-input time">
                        <input
                            type="time"
                            required={map.requ}
                            disabled={disabled}
                            className="form-modal-item-control time"
                            placeholder={map.hint || ''}
                            onChange={e => this.update(path, e.target.value)}
                            value={value || ''}
                        />
                    </div>
                </div>
            )
        } else if (map.type === 'dropdown') {
            return (
                <div className="form-modal-item dropdown" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                    </div>
                    <div className="form-modal-item-input dropdown">
                        <select
                            required={map.requ}
                            disabled={disabled}
                            className="form-modal-item-control dropdown"
                            onChange={e => this.update(path, e.target.value)}
                            value={value || map.list[0] || ''}>
                            {
                                map.list.map((item, key) => {
                                    if (typeof item === 'object') {
                                        return (
                                            <option value={item.data} key={key}>
                                                {item.name}
                                            </option>
                                        )
                                    } else {
                                        return (
                                            <option key={key}>{item}</option>
                                        )
                                    }
                                })
                            }
                        </select>
                    </div>
                </div>
            )
        } else if (map.type === 'checkbox') {
            return (
                <div className="form-modal-item checkbox" key={key}>
                    <div className="form-modal-item-input checkbox">
                        <label className="form-modal-item-label">
                            {map.name}
                            <input
                                type="checkbox"
                                required={map.requ}
                                disabled={disabled}
                                className="form-modal-item-control checkbox"
                                onChange={e => this.update(path, e.target.checked)}
                                checked={value || false}
                            />
                        </label>
                    </div>
                </div>
            )
        } else if (map.type === 'switch') {
            return (
                <div className="form-modal-item switch" key={key}>
                    <div className="form-modal-item-input switch">
                        <label className="form-modal-item-label">
                            {map.name}
                            <input
                                type="checkbox"
                                required={map.requ}
                                disabled={disabled}
                                className="form-modal-item-control switch"
                                onChange={e => this.update(path, e.target.checked)}
                                checked={value || false}
                            />
                        </label>
                    </div>
                </div>
            )
        } else if (map.type === 'object') {
            return (
                <div className="form-modal-list object" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                    </div>
                    <div className="form-modal-item-input">
                        {
                            map.list.map((cmap, ckey) => {
                                return (
                                    this.element(
                                        cmap,
                                        path + '.' + cmap.node,
                                        key + '_' + ckey
                                    )
                                )
                            })
                        }
                    </div>
                </div>
            )
        } else if (map.type === 'array') {
            return (
                <div className="form-modal-list array" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                        <div
                            onClick={() => this.pushItem(path, map.list)}
                            className="form-modal-array-button add"
                        />
                    </div>
                    <div className="form-modal-item-input">
                        {
                            value.map((_item, ckey) => {
                                return (
                                    <div className="form-modal-item-row" key={ckey}>
                                        {
                                            map.list.map((imap, ikey) => {
                                                // return element
                                                return this.element(
                                                    imap,
                                                    path + '.' + ckey + '.' + imap.node,
                                                    ikey
                                                )
                                            })
                                        }
                                        <div
                                            onClick={() => this.deltItem(path, ckey)}
                                            className="form-modal-array-button remove"
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        } else if (map.type === 'image') {
            const mode = this.props.loading ? 'loading' : typeof value === 'object'
                ? 'uploading' : !value ? 'empty' : 'ready'
            const isrc = asset(mode === 'ready' ? { id: value } : '', { height: 192 })
            const getImage = () => {
                if (disabled) { return }
                if (mode === 'ready' || mode === 'empty') {
                    if (this.imageInput.current) {
                        this.imageInput.current.click()
                    }
                }
            }
            const setImage = event => {
                this.update(path, event.target.files[0])
                event.target.value = ''
            }
            return (
                <div className="form-modal-item image" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                    </div>
                    <div className={"form-modal-item-input image " + mode} onClick={getImage}>
                        <div
                            className="form-modal-item-input image-inner"
                            style={{ backgroundImage: `url(${isrc})` }}>
                            Click to Upload
                            <br />
                            {map.hint}
                        </div>
                        <input
                            ref={this.imageInput}
                            type="file"
                            accept=".jpg,.png"
                            onInput={setImage}
                        />
                    </div>
                </div>
            )
        } else if (map.type === 'map') {
            return (
                <div className="form-modal-item map" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                    </div>
                    <div className="form-modal-item-input map">
                        <FormMap
                            coords={value}
                            loading={this.props.loading}
                            onChange={coords => this.update(path, coords)}
                            disabled={disabled}
                        />
                    </div>
                </div>
            )
        } else if (map.type === 'html') {
            return (
                <div className="form-modal-item html" key={key}>
                    <div className="form-modal-item-label">
                        {map.name}
                    </div>
                    <div className="form-modal-item-input html">
                        <FormHTML
                            loading={this.props.loading}
                            value={value || ''}
                            onChange={html => this.update(path, html)}
                            disabled={disabled}
                        />
                    </div>
                </div>
            )
        } else {
            return undefined
        }
    }

    // method to action buttons
    buttons() {
        // check editing mode
        if (this.props.disable) {
            // return back button
            return (
                <button
                    disabled={this.props.loading}
                    className="form-modal-form-button close"
                    onClick={this.close}>
                    Close
                </button>
            )
        } else {
            // return submit buttons
            return (
                <>
                    <button
                        disabled={this.props.loading}
                        className="form-modal-form-button action"
                        onClick={this.submit}>
                        Save
                    </button>
                    <button
                        disabled={this.props.loading}
                        className="form-modal-form-button cancel"
                        onClick={this.cancel}>
                        Cancel
                    </button>
                </>
            )
        }
    }

    // method to submit
    submit() {
        // return if loading
        if (this.props.loading) { return }
        // get form element
        const form = this.form.current
        // check form validity
        if (form && form.reportValidity()) {
            // callback event
            this.props.onevent({
                type: 'submit',
                value: exportObject(this.props.datamap, this.state, this.props.dataset)
            })
        }
    }

    // method to cancel
    cancel() {
        // return if loading
        if (this.props.loading) { return }
        // callback event
        this.props.onevent({
            type: 'cancel',
            value: exportObject(this.props.datamap, this.state, this.props.dataset)
        })
    }

    // method to close
    close() {
        // return if loading
        if (this.props.loading) { return }
        // callback event
        this.props.onevent({ type: 'close' })
    }

    // method to render
    render() {
        // form class name
        const name = 'form-modal' + (this.props.disable ? ' disable' : '')
        // return form dom
        return (
            <div className={name}>
                <div className="form-modal-content">
                    <form ref={this.form}>
                        {
                            // data mapping to elements
                            this.props.datamap.map((map, key) => {
                                // return element
                                return this.element(map, map.node, key)
                            })
                        }
                    </form>
                    <div className="form-modal-line">
                        <hr />
                    </div>
                    <div className="form-modal-form-buttons">
                        {this.buttons()}
                    </div>
                </div>
            </div>
        )
    }

}