import { createSlice, configureStore } from '@reduxjs/toolkit'

const slice = createSlice({
    // slice name
    name: 'common',
    // initial states
    initialState: {
        // prepaid and postpaid plan types
        PlanTypes: [],
        // prepaid plans array
        PrepaidPlans: [],
        // postpaid plans array
        PostpaidPlans: [],
        // question types
        FAQTypes: []
    },
    // reducers
    reducers: {
        // method to update values
        updateStore: (state, action) => {
            state[action.payload.key] = action.payload.value
        }
    }
})

export const { updateStore } = slice.actions

export default configureStore({ reducer: slice.reducer })