import React from 'react'
import API from '../helpers/api'
import { setLocal, updateStates } from '../helpers/helpers'
import Validator from '../helpers/validator'
import './Login.css'

// login component
export default class Login extends React.Component {
    // constructor
    constructor(props) {
        // super props
        super(props)
        // states
        this.state = {
            loading: false,
            username: '',
            password: '',
            erros: {
                username: null,
                password: null,
                response: null
            }
        }
        // method binds
        this.login = this.login.bind(this)
        this.usernameHandler = this.usernameHandler.bind(this)
        this.passwordHandler = this.passwordHandler.bind(this)
    }
    // method to nested update
    update(input, value) {
        // update nested states
        return updateStates(this, input, value)
    }
    // method to login
    async login() {
        // return if loading
        if (this.state.loading) { return }
        // clear all errors
        await this.update({ erros: { username: null, password: null, response: null } })
        // get username and password
        const username = this.state.username
        const password = this.state.password
        // get validations
        const validate = Validator({ username, password })
        // check validation
        if (validate.state === false) {
            // display errors
            await this.update({ erros: validate.erros })
        } else {
            // set loading
            await this.update({ loading: true })
            // request login
            API.login(username, password).then(resp => {
                // get token data
                const data = resp.data.data
                // decode token data
                data.data = JSON.parse(atob(data.access_token.split('.')[1]))
                // calculate expires time
                data.expires_time = Date.now() + data.expires
                // set data on local
                setLocal('user-data', data)
                // reload to continue
                window.location.reload()
            }).catch(error => {
                // check error code
                if (error.response && error.response.status === 400) {
                    // invalid email
                    this.update({ erros: { username: 'Invalid email' } })
                } else if (error.response && error.response.status === 401) {
                    // invalid user credentials
                    this.update({ erros: { password: 'Invalid user credentials' } })
                } else {
                    // login failed
                    this.update({ erros: { response: 'Login failed' } })
                }
                // stop loading
                this.update({ loading: false })
            })
        }
    }
    // username handler
    usernameHandler(event) {
        // update username value
        this.setState({ username: event.target.value })
        // clear errors
        this.update({ erros: { username: null, response: null } })
    }
    // password handler
    passwordHandler(event) {
        // update password value
        this.setState({ password: event.target.value })
        // clear errors
        this.update({ erros: { password: null, response: null } })
    }
    // method to render
    render() {
        return (
            <div className="login-box">
                <div className="login-box-left">
                    <div className="login-box-title">
                        Admin Portal
                        <div className="login-box-title-small">
                            Vodafone Vanuatu
                        </div>
                    </div>
                    <div className="login-box-form">
                        {/* username label */}
                        <div className="login-box-label">
                            Email
                        </div>
                        {/* username input */}
                        <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={this.state.username}
                            className="form-control"
                            onChange={this.usernameHandler}
                            disabled={this.state.loading}
                        />
                        {/* username error */}
                        <div className="login-box-error">
                            {this.state.erros.username}
                        </div>
                        {/* password label */}
                        <div className="login-box-label">
                            Password
                        </div>
                        {/* password input */}
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={this.state.password}
                            className="form-control"
                            onChange={this.passwordHandler}
                            disabled={this.state.loading}
                        />
                        {/* password error */}
                        <div className="login-box-error">
                            {this.state.erros.password}
                        </div>
                        {/* login error */}
                        <div className="login-box-error">
                            {this.state.erros.response}
                        </div>
                        {/* submit button */}
                        <button
                            className="submit"
                            onClick={this.login}
                            disabled={this.state.loading}>
                            {this.state.loading ? 'Please wait...' : 'Sign in'}
                        </button>
                    </div>
                </div>
                <div className="login-box-right"></div>
            </div>
        )
    }
}
