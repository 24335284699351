// validator object
const Validator = object => {
    // output object
    const output = { state: true, erros: {} }
    // get input keys
    const keys = Object.keys(object)
    // for each key
    for(let i = 0; i < keys.length; i++) {
        // current key
        const key = keys[i]
        // current value
        const val = object[key]
        // get validation message
        const msg = Validator[key](val)
        // check message
        if(msg !== true) {
            // update state flag
            output.state = false
            // update erros
            output.erros[key] = msg
        }
    }
    // return output
    return output
}

Validator.username = input => {
    return input.length === 0
        ? 'Please enter your email'
        : true
}

Validator.password = input => {
    return input.length === 0
        ? 'Please enter valid password'
        : true
}

export default Validator